import * as React from "react";
import { Contact, Layout } from "../../components";
import Seo from "../../components/Seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="東京横丁へのお問い合わせはこちらよりお願いします。"
        keywords="東京横丁,とうきょうよこちょう,TOKYO YOKOCHO,お問い合わせ,コンタクト,CONTACT"
      />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
